import React, {Component} from 'react';
import TweenLite from 'gsap';

import animateElement from '../utils/animations';

import photo from '../assets/images/foto.png';

class Cover extends Component {
	constructor(props){
		super(props);
		this.sectionRef = React.createRef();
		this.imageRef = React.createRef();
		this.mouseMove = this.mouseMove.bind(this);
	}

	componentDidMount(){
		if(this.sectionRef && this.sectionRef.current){
			let items = null;
			items = this.sectionRef.current.querySelectorAll('.text');
			items.forEach((item, i)=>{
				animateElement(item, 'top-opacity');
			})
			items = this.sectionRef.current.querySelectorAll('.image');
			items.forEach((item, i)=>{
				animateElement(item, 'shadow', 0.3);
				// animateElement(item, 'opacity', 0.3);
			})
		}
	}

	mouseMove(event){
		let image = event.target;
		image = this.imageRef.current;
		let bounding = image.getBoundingClientRect();
		let x = Math.min(1,Math.max(0,(event.clientX-bounding.x)/bounding.width))*2-1;
		let y = Math.min(1,Math.max(0,(event.clientY-bounding.y)/bounding.height))*2-1;
		TweenLite.to(image, 1, {'filter': 'drop-shadow('+(x*-20)+'px '+(y*-20)+'px 3px #03212c)'});
	}
	
	render(){

		return(
			<section className="section section-cover" onMouseMove={this.mouseMove} ref={this.sectionRef}>
				<div className="cover-container medium-width">
					<div className="cover-text-container">
						<p className="text font-h2 font-white">Jose Guadix</p>
						<p className="text font-h1 font-white">Desarrollador Web</p>
						<h1 className="text font-p font-white">Ingeniero informático con 7 años de experiencia <br className="br-only-desktop"/> en el desarrollo de aplicaciones y páginas web.</h1>
						<p className="text font-p font-white">
							<a href="https://www.linkedin.com/in/joseguadix/" target="_blank" rel="noopener noreferrer" className="font-p font-white font-underline">Ver perfil en LinkedIn</a>
						</p>
					</div>
					<div className="cover-image-container">
						<img className="image" src={photo} alt="Jose Guadix - Página personal" ref={this.imageRef}/>
					</div>
				</div>
			</section>
		);
	}
}

export default Cover;


