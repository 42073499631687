import React from "react";
import { Route, Switch, BrowserRouter as Router} from "react-router-dom";

import PageLanding from "./containers/PageLanding";

import './css/style.css';


class App extends React.Component{
	render(){
		return (
			<Router>
				<Switch>
					<Route path="/reset-password" component={PageLanding} />
					<Route path="/login" component={PageLanding} />
					<Route path="/" component={PageLanding} />
				</Switch>
			</Router>
		);
	}
}

export default App;


