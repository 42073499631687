import React, {Component} from 'react';

import animateElement from '../utils/animations';

let svgExternalLink = <svg className="external-link" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/><polyline points="15 3 21 3 21 9"/><line x1="10" x2="21" y1="14" y2="3"/></svg>;

class Experience extends Component {
	constructor(props){
		super(props);

		this.state = {
			popupActive : '',
		}

		this.sectionRef = React.createRef();
		this.popupContainerRef = React.createRef();
		this.openPopup = this.openPopup.bind(this);
		this.closePopup = this.closePopup.bind(this);
	}

	componentDidMount(){
		if(this.sectionRef && this.sectionRef.current){
			let items = null;
			items = this.sectionRef.current.querySelectorAll('.section-title,.experience-item');
			items.forEach((item, i)=>{
				animateElement(item, 'top-opacity');
			})
		}
	}

	openPopup(event){
		this.popupContainerRef.current.querySelector('.popup-content').innerHTML = event.currentTarget.querySelector('.popup-content').innerHTML;
		this.popupContainerRef.current.scrollTo(0,0);
		let items = this.popupContainerRef.current.querySelectorAll('.main-image-container,.project-gallery-item');
		items.forEach((item, i)=>{
			animateElement(item, 'top-opacity-video');
		})
		animateElement(this.popupContainerRef.current.querySelector('.project-details'), 'top-opacity');
		
		document.body.classList.add('overflow-hidden');
		this.setState({
			popupActive: 'active',
		});
	}

	closePopup(){
		document.body.classList.remove('overflow-hidden');
		this.setState({
			popupActive: '',
		});
	}


	
	render(){

		return(
			<section className="section section-experience" ref={this.sectionRef}>
				<div className="medium-width">
					<p className="section-title font-h1 font-blue">Proyectos destacados</p>
					<div className="experience-container">

						<ExperienceItem folderName="banjo" title="Banjo" link="https://banjosoundscapes.com/" year="2022" videos={[{number: '2'},{number: '7'},{number: '3'},{size: 'small', number: '5'},{size: 'small', number: '6'},{number: '4'}]}  openPopup={this.openPopup}/>
						
						<ExperienceItem folderName="dibi" title="Dibi" link="https://dibi.cat/" year="2022" videos={[{number: '2'},{size: 'small', number: '4'},{size: 'small', number: '5'},{number: '3'}]}  openPopup={this.openPopup}/>
						
						<ExperienceItem folderName="lapele" title="La Pele" link="https://lapele.es/" year="2021" videos={[{number: '2'},{number: '3'},{size: 'small', number: '5'},{size: 'small', number: '6'},{number: '4'}]}  openPopup={this.openPopup}/>
						
						<ExperienceItem folderName="naggura" title="Naggura" link="https://www.naggura.com/naggura/es/" year="2021" videos={[{number: '2'},{number: '3'},{size: 'small', number: '5'},{size: 'small', number: '6'},{number: '4'}]}  openPopup={this.openPopup}/>
						
						<ExperienceItem folderName="cafler" title="Cafler" link="https://cafler.es/" year="2021" videos={[{number: '2'},{number: '3'},{size: 'small', number: '5'},{size: 'small', number: '6'},{number: '4'}]}  openPopup={this.openPopup}/>
						
						<ExperienceItem folderName="pronatal" title="Pronatal" link="https://www.clinicaspronatal.com/" year="2021" videos={[{number: '2'},{size: 'small', number: '4'},{size: 'small', number: '5'},{number: '3'}]}  openPopup={this.openPopup}/>
						
						<ExperienceItem folderName="dev" longTitle="Disfrute en vida - Campofrío Navidad 2020" title="Disfrute en vida" year="2020" videos={[{number: '2'},{size: 'small', number: '4'},{size: 'small', number: '5'},{number: '3'}]}  openPopup={this.openPopup}/>
						
						<ExperienceItem folderName="soultek" title="Soultek" link="https://soultek.es/" year="2020" videos={[{size: 'small', number: '3'},{size: 'small', number: '4'},{number: '2'}]}  openPopup={this.openPopup}/>

					</div>
				</div>
				<div className={"popup-container " + this.state.popupActive} ref={this.popupContainerRef}>
					<div className="close font-p" onClick={this.closePopup}>Cerrar</div>
					<div className="medium-width">
						<div className="popup-content">
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Experience;

const ExperienceItem = (props) => (
	<div className="experience-item" onClick={props.openPopup}>
		<div className="image-container">
			<img className='image' src={require('../assets/projects/'+props.folderName+'/duotone.jpg')} />
			<div className="mask">
				<p className="experience-title font-h2">{props.longTitle || props.title}</p>
			</div>
		</div>
		<div className="popup-content">
		
			<div className="main-image-container">
				<video className='image' loop muted playsInline>
					<source src={require('../assets/projects/'+props.folderName+'/1.mp4')} />
				</video>
			</div>

			<div className="project-details">
				{(props.link)?(
					<a className="project-title font-h1" href={props.link} target="_blank" rel="noopener noreferrer">{props.title} {svgExternalLink}</a>
				):(
					<p className="project-title font-h1">{props.title}</p>
				)}
				<p className="project-year font-h2">{props.year}</p>
			</div>

			<div className="project-gallery">
				{props.videos.map((item, i) => (
					<div className={"project-gallery-item " + (item.size || 'big')} key={props.folderName + '-' + i}>
						<video className='img-video' loop muted playsInline>
							<source src={require('../assets/projects/'+props.folderName+'/'+item.number+'.mp4')} />
						</video>
					</div>
				))}
			</div>
		</div>
	</div>
)