import React, {Component} from 'react';

import animateElement from '../utils/animations';

class Contact extends Component {
	constructor(props){
		super(props);

		this.sectionRef = React.createRef();
	}

	componentDidMount(){
		if(this.sectionRef && this.sectionRef.current){
			let items = this.sectionRef.current.querySelectorAll('.section-title,.contact-container');
			items.forEach((item, i)=>{
				animateElement(item, 'top-opacity');
			})
		}
	}

	handleEmailClick(event){
		let mail = 'to:hola@';
		event.target.href = 'mail'+mail+'joseguadix.es';
	}
	
	render(){

		return(
			<section className="section section-contact with-padding" ref={this.sectionRef}>
				<div className="medium-width">
					<p className="section-title font-h1 font-white">¿Hablamos?</p>
					<div className="contact-container">
						<p className="contact-detail font-p font-white">Si tienes un proyecto donde podemos trabajar juntos o simplemente estás interesado en mi perfil no dudes en hablarme.</p>
						<p className="contact-detail font-p font-white">
							📧 <a href="mailto:" onClick={this.handleEmailClick} className="font-p font-white font-underline">hola@joseguadix.es</a>
						</p>
						<p className="contact-detail font-p font-white">
							<a href="https://www.linkedin.com/in/joseguadix/" target="_blank" rel="noopener noreferrer" className="font-p font-white font-underline">LinkedIn</a>
						</p>
					</div>
					<div className="footer-container">
						<p className="contact-detail font-p3 font-white">Copyright © {(new Date()).getFullYear()}. Desarrollado por Jose Guadix</p>
					</div>
				</div>
			</section>
		);
	}
}

export default Contact;


