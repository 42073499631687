import TweenLite from 'gsap';

let imageObserver = null;

if(imageObserver === null){
	imageObserver = new IntersectionObserver(
		function(entries, observer){ 
			var myDelay = 0.15;
			entries.forEach( function(elem, index){
				if(  elem.isIntersecting ) {
					elem = elem.target;
					imageObserver.unobserve(elem);
					myDelay += 0.15;
					
					myDelay = _animateElement(elem, myDelay);
				}
			});
		}, 
		{rootMargin: "0px 0px -"+window.innerHeight*0.15+"px 0px"}
	);
}

function animateElement(element, animationType, extraDelay = 0){
	element.data = element.data || {};
	element.data['animationType'] = animationType;
	element.data['extraDelay'] = extraDelay;
	_prepareElement(element);
	imageObserver.observe(element);
}

function _prepareElement(element){
	let animationType = 'opacity';
	if(element.data && element.data.animationType){
		animationType = element.data.animationType;
	}

	if(animationType === 'top-opacity'){
		TweenLite.set(element, {opacity: 0, y: 40});
	}else if(animationType === 'top-opacity-video'){
		TweenLite.set(element, {opacity: 0, y: 40});
	}else if(animationType === "shadow"){
		TweenLite.set(element, {opacity: 0, 'filter': 'drop-shadow(0px 0px 3px #03212c)'});
	}else{
		TweenLite.set(element, {opacity: 0});
	}
}


function _animateElement(element, myDelay){
	myDelay = Math.max(0, myDelay);
	let animationType = 'opacity';
	if(element.data && element.data.animationType){
		animationType = element.data.animationType;
	}
	if(element.data && element.data.extraDelay){
		myDelay += element.data.extraDelay;
	}

	if(animationType === "top-opacity"){
		TweenLite.to(element, 1, {opacity: 1, y: 0, clearProps: 'pointer-events', delay: myDelay});
	}else if(animationType === 'top-opacity-video'){
		element.querySelector('video').play();
		TweenLite.to(element, 1, {opacity: 1, y: 0, clearProps: 'pointer-events', delay: myDelay});
	}else if(animationType === "shadow"){
		TweenLite.to(element, 1.5, {opacity: 1, delay: myDelay});
		TweenLite.to(element, .8, {'filter': 'drop-shadow(20px 20px 3px #03212c)', delay: myDelay+.5});
	}else{
		TweenLite.to(element, 1.5, {opacity: 1, delay: myDelay});
	}
	return myDelay;
}

export default animateElement;