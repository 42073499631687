import React, {Component} from "react";

class MenuTop extends Component {
	constructor(props){
		super(props);

		this.menuTopRef = React.createRef();

		this.handleNavegation = this.handleNavegation.bind(this);
		this.handleChangeHeader = this.handleChangeHeader.bind(this);
		this.showMenuMobile = this.showMenuMobile.bind(this);
		this.hideMenuMobile = this.hideMenuMobile.bind(this);
	}

	componentDidMount(){
		window.addEventListener('scroll', this.handleChangeHeader);
	}
	componentWillUnmount(){
		window.removeEventListener('scroll', this.handleChangeHeader);
	}

	handleChangeHeader(){
		let top = window.innerHeight*0.5;
		let scrollTop = window.scrollY;
		
		if(scrollTop > top){
			this.menuTopRef.current.classList.remove('menu-black');
		}else{
			this.menuTopRef.current.classList.add('menu-black');
		}
	}

	handleNavegation(event){
		this.props.menuClick(event.target.getAttribute('data-to'));
		this.hideMenuMobile();
	}

	showMenuMobile(){
		this.menuTopRef.current.querySelector('.menu-top-right-container').classList.add('active');
		setTimeout(()=>{
			window.addEventListener('click', this.hideMenuMobile);
		}, 100);
	}

	hideMenuMobile(){
		this.menuTopRef.current.querySelector('.menu-top-right-container').classList.remove('active');
		window.removeEventListener('click', this.hideMenuMobile);
	}

	render(){
		return(
	        <div className="menu-top menu-black" ref={this.menuTopRef}>
		        <div className="menu-top-container medium-width">
					<div className="menu-top-left-container">
						<p className="menu-top-item font-p " data-to="cover" onClick={this.handleNavegation}>Jose Guadix</p>
					</div>
					<div className="menu-top-right-container">
						<p className={"menu-top-item font-p " + ((this.props.sectionActive==='projects')?'active':'')} data-to="projects" onClick={this.handleNavegation}>Proyectos</p>
						<p className={"menu-top-item font-p " + ((this.props.sectionActive==='technologies')?'active':'')} data-to="technologies" onClick={this.handleNavegation}>Tecnologías</p>
						<p className={"menu-top-item font-p " + ((this.props.sectionActive==='contact')?'active':'')} data-to="contact" onClick={this.handleNavegation}>Contacto</p>
					</div>
					<div className="menu-top-icon-mobile" onClick={this.showMenuMobile}>
						<div className="line"></div>
						<div className="line"></div>
						<div className="line"></div>
					</div>
		        </div>
	        </div>
		);
	}
}


export default MenuTop;
