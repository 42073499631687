import React, {Component} from 'react';

import animateElement from '../utils/animations';

import css from '../assets/images/tech/css.png';
import html from '../assets/images/tech/html.png';
import javascript from '../assets/images/tech/javascript.png';
import expressjs from '../assets/images/tech/expressjs.png';
import nodejs from '../assets/images/tech/nodejs.png';
import reactjs from '../assets/images/tech/reactjs.png';
import mongodb from '../assets/images/tech/mongodb.png';
import mysql from '../assets/images/tech/mysql.png';
import php from '../assets/images/tech/php.png';
import aws from '../assets/images/tech/aws.png';
import awss3 from '../assets/images/tech/aws-s3.png';
import awsec2 from '../assets/images/tech/aws-ec2.png';
import git from '../assets/images/tech/git.png';
import wordpress from '../assets/images/tech/wordpress.png';

class Technologies extends Component {
	constructor(props){
		super(props);

		this.sectionRef = React.createRef();
	}

	componentDidMount(){
		if(this.sectionRef && this.sectionRef.current){
			let items = null;
			items = this.sectionRef.current.querySelectorAll('.section-title,.technology-item,.capability');
			items.forEach((item, i)=>{
				animateElement(item, 'top-opacity');
			})
		}
	}
	
	render(){

		return(
			<section className="section section-technologies" ref={this.sectionRef}>
				<div className="medium-width">
					<p className="section-title font-h1 font-blue">Tecnologías usadas</p>
					<div className="capabilities-container">
						<p className="capability li-circle font-p">Desarrollo de aplicaciones web y páginas web con ReactJS, NodeJS, ExpressJS, MongoDB y MySQL.</p>
						<p className="capability li-circle font-p">Desarrollo de páginas web con PHP, HTML5, CSS3, JavaScript y MySQL.</p>
						<p className="capability li-circle font-p">Uso de GIT como sistema de control de versiones.</p>
						<p className="capability li-circle font-p">Manejo de herramientas para gestionar tareas, como Asana y Teamwork.</p>
						<p className="capability li-circle font-p">Uso de Frameworks como Wordpress para el desarrollo de temas y pluguins customizados.</p>
						<p className="capability li-circle font-p">Conexiones con APIs de terceros como Google Maps, Google Drive, Amazon S3, CRMs, aplicaciones de newsletter como Mailchimp, conexión con pasarelas de pago, entre otras.</p>
					</div>
					<div className="technologies-container">
						<div className="technology-item">
							<img className="image" src={nodejs} alt=""/>
						</div>
						<div className="technology-item">
							<img className="image" src={expressjs} alt=""/>
						</div>
						<div className="technology-item">
							<img className="image" src={reactjs} alt=""/>
						</div>
						<div className="technology-item">
							<img className="image" src={mongodb} alt=""/>
						</div>
						<div className="technology-item">
							<img className="image" src={git} alt=""/>
						</div>
						<div className="technology-item">
							<img className="image" src={php} alt=""/>
						</div>
						<div className="technology-item">
							<img className="image" src={mysql} alt=""/>
						</div>
						<div className="technology-item">
							<img className="image" src={html} alt=""/>
						</div>
						<div className="technology-item">
							<img className="image" src={css} alt=""/>
						</div>
						<div className="technology-item">
							<img className="image" src={javascript} alt=""/>
						</div>
						<div className="technology-item">
							<img className="image" src={wordpress} alt=""/>
						</div>
						<div className="technology-item">
							<img className="image" src={awss3} alt=""/>
						</div>
						<div className="technology-item">
							<img className="image" src={awsec2} alt=""/>
						</div>
						<div className="technology-item">
							<img className="image" src={aws} alt=""/>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Technologies;


