import React, {Component} from "react";
import TweenLite from 'gsap';

import MenuTop from '../components/MenuTop';
import Cover from '../components/Cover';
import Technologies from '../components/Technologies';
import Experience from '../components/Experience';
import Contact from '../components/Contact';

class PageLanding extends Component {
	constructor(props){
		super(props);

		this.state = {
			sectionActive: '' 
		}

		this.menuTopRef = React.createRef();
		this.sectionRefs = {
			cover: React.createRef(),
			projects: React.createRef(),
			technologies: React.createRef(),
			contact: React.createRef(),
		}
		
		this.handleMenuClick = this.handleMenuClick.bind(this);
		this.checkSectionActive = this.checkSectionActive.bind(this);
	}

	componentDidMount(){
		try {  
			document.createEvent("TouchEvent");  
			document.querySelector("body").classList.add("touch-device");
		} catch (e) {  
			document.querySelector("body").classList.remove("touch-device");
		}
		
		window.addEventListener('scroll', this.checkSectionActive);
		
		this.checkSectionActive();
	}

	componentWillUnmount(){
		window.removeEventListener('scroll', this.checkSectionActive);
	}

	handleMenuClick(sectionName){
		let ref = this.sectionRefs[sectionName];
		if(ref && ref.current && ref.current.sectionRef && ref.current.sectionRef.current){
			var scroll = {left:window.scrollX, top: window.scrollY}
			TweenLite.to(scroll, 1, {top: ref.current.sectionRef.current.offsetTop-100, onUpdate:function(){window.scrollTo(scroll.left, scroll.top)}});
		}
	}

	checkSectionActive(){
		let top = window.innerHeight*.6;
		let sectionActive = '';
		for(let sectionName in this.sectionRefs){
			if(this.sectionRefs[sectionName].current.sectionRef.current.offsetTop - window.scrollY < top){
				sectionActive = sectionName;
			}
		}
		if(this.state.sectionActive !== sectionActive){
			this.setState({sectionActive: sectionActive});
		}
	}
	
	render(){

		return(
			<div className="page-landing " >
				<MenuTop sectionActive={this.state.sectionActive} menuClick={this.handleMenuClick}/>

				<Cover ref={this.sectionRefs.cover}/>
				<Experience ref={this.sectionRefs.projects}/>
				<Technologies ref={this.sectionRefs.technologies}/>
				<Contact ref={this.sectionRefs.contact}/>
			</div>
		);
	}
}

export default PageLanding;


